import React, { memo, useEffect, useMemo, useState } from 'react';
import { Wrapper } from './homepage.styled';
import { Button, Form, Input, Modal, Popconfirm, Select, Table, Typography } from 'antd';
import CSVReader from 'react-csv-reader';
import Icon1 from '@/assets/ic1.png';
import Icon2 from '@/assets/ic2.png';
import Edit from '@/assets/mingcute_edit-line.png';
import Delete from '@/assets/mingcute_delete-2-line.png';
import { mkConfig, download, generateCsv } from 'export-to-csv';
import {
  useActiveAccount,
  useActiveWalletChain,
  useConnectModal,
  useSwitchActiveWalletChain,
  useWalletBalance
} from 'thirdweb/react';
import { client } from '@/app/client';
import { toast } from 'react-toastify';
import { SUPPORTED_NETWORKS_TRANSFER } from '@/config/network.config';
import { useTransfer } from './hooks/useTransfer';
import { isAddress } from 'thirdweb';
import BigNumber from 'bignumber.js';

const renderColumn = ['privyid', 'walletaddress', 'points', 'rewards'];
const editableColumn = ['rewards'];

interface EditableCellProps extends React.HTMLAttributes<HTMLElement> {
  editing: boolean;
  dataIndex: string;
  title: any;
  inputType: 'number' | 'text';
  record: any;
  index: number;
}

const EditableCell: React.FC<React.PropsWithChildren<EditableCellProps>> = ({
  editing,
  dataIndex,
  title,
  inputType,
  record,
  index,
  children,
  ...restProps
}) => {
  const inputNode = <Input />;

  return (
    <td {...restProps}>
      {editing ? (
        <Form.Item
          name={dataIndex}
          style={{ margin: 0 }}
          rules={[
            {
              required: true,
              message: `Please Input ${title}!`
            }
          ]}
        >
          {inputNode}
        </Form.Item>
      ) : (
        children
      )}
    </td>
  );
};

const HomePageComponent = () => {
  const [form] = Form.useForm();
  const [editingKey, setEditingKey] = useState('');

  const isEditing = (record: any) => record.privyid === editingKey;

  const edit = (record: any & { key: React.Key }) => {
    console.log('giang', record);
    form.setFieldsValue({ privyid: '', walletaddress: '', points: '', rewards: '', ...record });
    setEditingKey(record.privyid);
  };

  const cancel = () => {
    setEditingKey('');
  };

  const switchChain = useSwitchActiveWalletChain();
  const [rewardData, setRewardData] = useState<any>([]);
  const [tableColumns, setTableColumns] = useState<any>([]);
  const [tableData, setTableData] = useState<any>([]);
  const [activeNetwork, setActiveNetwork] = useState(0);

  const save = async (privyid: string) => {
    try {
      const row = (await form.validateFields()) as any;

      const newData = [...tableData];
      const index = newData.findIndex((item) => privyid === item.privyid);
      console.log('saveItem', privyid, newData, index, row);
      if (index > -1) {
        const item = newData[index];
        newData.splice(index, 1, {
          ...item,
          ...row
        });
        console.log('newData', newData);
        setTableData(newData);
        setEditingKey('');
      } else {
        newData.push(row);
        console.log('newData', newData);
        setTableData(newData);
        setEditingKey('');
      }
    } catch (errInfo) {
      console.log('Validate Failed:', errInfo);
    }
  };
  console.log('activeNetwork', activeNetwork);
  useEffect(() => {
    const rootKey = rewardData?.[0];
    if (!rootKey) return;
    let columns: any = [];
    rootKey?.map((item: any) => {
      if (renderColumn.includes(item?.replace(/ /g, '')?.toLowerCase()))
        columns.push({
          title: item,
          dataIndex: item?.replace(/ /g, '')?.toLowerCase(),
          key: item?.replace(/ /g, '')?.toLowerCase(),
          editable: !!editableColumn?.includes(item?.replace(/ /g, '')?.toLowerCase())
        });
    });
    if (columns?.length > 0) {
      columns.push({
        title: 'Actions',
        render: (record: any) => {
          const editable = isEditing(record);
          return editable ? (
            <span>
              <Typography.Link onClick={() => save(record.privyid)} style={{ marginInlineEnd: 8 }}>
                Save
              </Typography.Link>
              <Popconfirm title='Sure to cancel?' onConfirm={cancel}>
                <a>Cancel</a>
              </Popconfirm>
            </span>
          ) : (
            <div className='flex items-center gap-2'>
              <div
                className='py-2 px-3 rounded-full flex items-center justify-center border-[1px] border-[#E4E4E7] cursor-pointer'
                onClick={() => {
                  edit(record);
                }}
              >
                <img src={Edit} className='w-3' alt='' />
              </div>
              <div
                className='py-2 px-3 rounded-full flex items-center justify-center border-[1px] border-[#E4E4E7] cursor-pointer'
                onClick={() => {
                  onDelete(record);
                }}
              >
                <img src={Delete} className='w-3' alt='' />
              </div>
            </div>
          );
        },
        key: '100000'
      });
    }
    setTableColumns(columns);
  }, [rewardData, editingKey]);

  useEffect(() => {
    const rootKey = rewardData?.[0];
    if (!rootKey) return;
    const _rewardData = [...rewardData];
    const dataSource: any = [];
    _rewardData.shift();
    _rewardData?.map((item: any) => {
      console.log('item', item);
      let parseItem = item?.reduce(function (result: any, item: any, index: any, array: any) {
        result[`${rootKey?.[index]?.replace(/ /g, '')?.toLowerCase()}`] = item;
        return result;
      }, {});
      if (parseItem?.privyid) dataSource.push(parseItem);
    });

    console.log('newData', 1);
    setTableData(dataSource);
  }, [rewardData]);

  console.log('tableData', tableData);

  const onDelete = (record: any) => {
    console.log('record?.privyid', record?.privyid);
    Modal.confirm({
      centered: true,
      title: 'Are you sure, you want to delete this record?',
      onOk: () => {
        setTableData((pre: any) =>
          pre?.filter((rc: any) => {
            return rc?.privyid !== record?.privyid;
          })
        );
      }
    });
  };
  console.log('tesst', rewardData, tableData);
  const { connect } = useConnectModal();
  const activeAccount = useActiveAccount();
  const activeChain = useActiveWalletChain();
  const [tokenAddress, setTokenAddress] = useState('');

  const transferData = useMemo(() => {
    let receiverAddress: string[] = [];
    let receiverAmount: string[] = [];
    let totalAmount: number = 0;
    let totalParticipants: number = 0;
    let totalFailed: number = 0;
    tableData?.map((item: any) => {
      if (isAddress(item?.walletaddress) && !!Number(item?.rewards)) {
        receiverAddress.push(item?.walletaddress);
        receiverAmount.push(item?.rewards?.toString());
        totalAmount = totalAmount + Number(item?.rewards);
        totalParticipants = totalParticipants + 1;
      } else {
        totalFailed = totalFailed + 1;
      }
    });
    if (totalFailed > 2) {
      toast.dismiss();
      toast.info(
        'Many rows lack wallet addresses. Please check again; users without a wallet address won’t receive token rewards.'
      );
    }
    return {
      receiverAddress,
      receiverAmount,
      totalAmount,
      totalParticipants
    };
  }, [tableData]);
  const chain = SUPPORTED_NETWORKS_TRANSFER?.[activeNetwork]?.network;
  const { data: accountTokenBalance } = useWalletBalance(
    {
      chain,
      address: activeAccount?.address,
      client,
      tokenAddress
    },
    {
      refetchInterval: 8000
    }
  );
  const { handleTransfer, isLoading, isApprove, tx } = useTransfer({
    tokenAddress,
    receiverAddress: transferData?.receiverAddress,
    receiverAmount: transferData?.receiverAmount,
    totalAmount: transferData?.totalAmount?.toString(),
    onSuccess: () => {},
    chain: SUPPORTED_NETWORKS_TRANSFER?.[activeNetwork]?.network,
    onFailed: () => {},
    accountTokenBalance
  });
  console.log('activeChain', activeChain);
  useEffect(() => {
    if (activeChain?.id && activeChain?.id !== SUPPORTED_NETWORKS_TRANSFER?.[activeNetwork]?.network?.id) {
      toast.dismiss();
      toast.info(
        `Network missmatch. Changing from ${activeChain?.id} to ${SUPPORTED_NETWORKS_TRANSFER[activeNetwork]?.network?.id}`
      );
      switchChain(SUPPORTED_NETWORKS_TRANSFER[activeNetwork]?.network);
    }
  }, [activeNetwork, activeChain]);

  console.log('activeAccount', activeAccount);

  const isEnoughToken = useMemo(() => {
    if (!accountTokenBalance) return true;
    return BigNumber(accountTokenBalance?.displayValue)?.gte(transferData?.totalAmount);
  }, [accountTokenBalance, transferData]);

  console.log('eidit', editingKey);

  const mergedColumns = tableColumns.map((col: any) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record: any) => ({
        record,
        inputType: 'text',
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record)
      })
    };
  });

  console.log('mergedColumns', mergedColumns);
  console.log('tableData', tableData);

  return (
    <Wrapper>
      <div className='w-full max-w-[1200px] mt-20 bg-[#F4F4F5] p-7 border-[1px] border-[#E4E4E7]'>
        <div className='w-full flex items-start gap-4'>
          <div className='p-4 flex items-center gap-4 border-[1px] rounded-lg border-[#E4E4E7] bg-[#FFFFFF]'>
            <img src={Icon1} className='w-6' alt='' />
            <span className='font-medium text-sm text-[#838383]'>Total Participants</span>
            <span className='font-semibold text-xl'>{transferData?.totalParticipants}</span>
          </div>
          <div className='p-4 flex items-center gap-4 border-[1px] rounded-lg border-[#E4E4E7] bg-[#FFFFFF]'>
            <img src={Icon2} className='w-6' alt='' />
            <span className='font-medium text-sm text-[#838383]'>Total number of tokens rewarded</span>
            <span className='font-semibold text-xl'>
              {transferData?.totalAmount} {accountTokenBalance?.symbol || ''}
            </span>
          </div>
        </div>
        <div className='w-full flex gap-4  mt-4 items-center'>
          {accountTokenBalance && tokenAddress && (
            <div className='p-4 flex items-center gap-4 border-[1px] rounded-lg border-[#E4E4E7] bg-[#FFFFFF] w-fit'>
              <img src={Icon1} className='w-6' alt='' />
              <span className='font-medium text-sm text-[#838383]'>{accountTokenBalance?.symbol} Balance:</span>
              <span className='font-semibold text-xl'>
                {accountTokenBalance?.displayValue} {accountTokenBalance?.symbol}
              </span>
            </div>
          )}
          {!isEnoughToken && tokenAddress && (
            <div className='text-red-600 font-bold text-sm '>Insufficient {accountTokenBalance?.symbol} balance! </div>
          )}
        </div>
        <div className='w-full flex items-center gap-3 mt-5'>
          <div className='flex-[0.65] flex flex-col gap-2'>
            <div className='text-sm'>Rewards token address</div>
            <div className='app-input py-2 px-3 bg-[#FFFFFF] border-[1px] border-[#E4E4E7] rounded-md'>
              <input
                type='text'
                className='w-full'
                placeholder='Paste token address here'
                value={tokenAddress}
                onChange={(e) => {
                  setTokenAddress(e.target.value);
                }}
              />
            </div>
          </div>
          <div className='flex-[0.25] flex flex-col gap-2'>
            <div className='text-sm'>Reward network</div>
            <Select
              onChange={(item) => {
                setActiveNetwork(item);
              }}
              className='h-[43px]'
              defaultValue={0}
            >
              {SUPPORTED_NETWORKS_TRANSFER?.map((item: any, key: number) => {
                return (
                  <Select.Option value={key} key={key}>
                    <div className='flex items-center gap-2'>
                      <img src={item?.icon} className='w-6' alt='' />
                      <span>{item?.network?.name}</span>
                    </div>
                  </Select.Option>
                );
              })}
            </Select>
          </div>
          <div className='flex-[0.25] relative self-end'>
            <Button
              loading={isLoading}
              className='connect-button relative'
              onClick={() => {
                if (!activeAccount?.address) connect({ client });
                else {
                  handleTransfer();
                }
              }}
            >
              {!activeAccount?.address ? ' Connect Wallet' : !isApprove ? 'Approve token' : 'Send'}
            </Button>
          </div>
        </div>
        <div className='mt-20 p-6 bg-[#FFFFFF] rounded-xl'>
          {tx && (
            <div className='w-full flex items-center justify-between'>
              <div className='flex items-center gap-4'>
                <div
                  className='font-medium text-sm text-[#838383]'
                  onClick={() => {
                    window.navigator.clipboard.writeText(tx);
                  }}
                >
                  Transaction hash: <span className='font-medium text-sm text-[#1900FF]'>{tx}</span>
                </div>
              </div>
              <div
                className='cursor-pointer font-semibold text-[18px]'
                onClick={() => {
                  window.open(
                    SUPPORTED_NETWORKS_TRANSFER?.[activeNetwork]?.network?.blockExplorers?.[0]?.url + '/tx/' + tx
                  );
                }}
              >
                View txn
              </div>
            </div>
          )}
          <Form form={form} component={false}>
            <Table
              components={{
                body: { cell: EditableCell }
              }}
              dataSource={tableData}
              columns={mergedColumns}
              pagination={{ defaultPageSize: 5, showSizeChanger: true, pageSizeOptions: ['5', '10', '20', '30'] }}
            />
          </Form>

          <div className='w-full flex items-center justify-end gap-2 mt-4'>
            <Button className='import-button w-fit overflow-hidden cursor-pointer'>
              <CSVReader
                onFileLoaded={(data, fileInfo, originalFile) => {
                  setRewardData(data);
                  console.log('data', data, fileInfo, originalFile);
                }}
              />
              Import CSV
            </Button>
            <Button
              className='export-button'
              onClick={() => {
                console.log('tableData', tableData);
                if (tableData?.length === 0) {
                  const csvConfig = mkConfig({ useKeysAsHeaders: true, filename: 'example' });
                  const exampleData = [
                    {
                      privyid: 'cm36zjyf8013zq1icm8h48roi',
                      walletaddress: '0x085E84CdFAad91DDb919611f3408E126017738fb',
                      points: '180',
                      twitter: 'NoRealPunk',
                      discord: 'kirabytez#0',
                      email: 'realmekira1996@gmail.com'
                    },
                    {
                      privyid: 'cm36tedzf04qyxr8wv0xbhndu',
                      walletaddress: '0x143ae2e884deeA63aBC37aba3cBCcfa6cc64cA5c',
                      points: '165',
                      twitter: 'jannicole623',
                      discord: 'niknows#0',
                      email: 'jannicole623@yahoo.com'
                    },
                    {
                      privyid: 'cm3mekprm000vv3wgtfl10enn',
                      walletaddress: '-',
                      points: '130',
                      twitter: 'huang99555',
                      discord: '-',
                      email: '-'
                    },
                    {
                      privyid: 'cm0q0z361033f1mdh8me4ovcz',
                      walletaddress: '0x3Eff8fe81808b8F81C0d36a5bAb2fe00C92C637e',
                      points: '75',
                      twitter: '-',
                      discord: 'shinoaki6228#0',
                      email: 'taki@avocadoguild.com'
                    },
                    {
                      privyid: 'cm33r5j29037z10cgti8scbfz',
                      walletaddress: '0x64d755cB2fDf48677D0306C630C0B209917A6Dd4',
                      points: '50',
                      twitter: 'extrasteamrice',
                      discord: '-',
                      email: 'chang@avocadoguild.com'
                    },
                    {
                      privyid: 'cm0kf13ze035lv0ib3q7sxilc',
                      walletaddress: '0xe9E0aBc0bDED21B948ba9C79BdE8EC801e9439a0',
                      points: '20',
                      twitter: '-',
                      discord: 'agant22#0',
                      email: 'antony@avocadoguild.com'
                    },
                    {
                      privyid: 'cm35m0ykh0223lvewgtk4kg1l',
                      walletaddress: '0xeB7169F3F11eBC076e9A837B9c1E0B4DE44203A1',
                      points: '15',
                      twitter: 'LuisBote96',
                      discord: 'notpickmegurl#0',
                      email: 'lusibote4@gmail.com'
                    },
                    {
                      privyid: 'cm0nxo0qy00s5wpvupp1e6d6s',
                      walletaddress: '-',
                      points: '10',
                      twitter: '-',
                      discord: '-',
                      email: 'decruz@gmail.com'
                    },
                    {
                      privyid: 'cm0ufudav003r13ug5o59zh31',
                      walletaddress: '-',
                      points: '10',
                      twitter: '-',
                      discord: '-',
                      email: 'kira.rehmnants@gmail.com'
                    }
                  ];
                  const csv = generateCsv(csvConfig)(exampleData);
                  console.log('csv', csv);
                  download(csvConfig)(csv);
                } else {
                  const csvConfig = mkConfig({ useKeysAsHeaders: true, filename: 'rewards' });
                  const csv = generateCsv(csvConfig)(tableData);
                  download(csvConfig)(csv);
                }
              }}
            >
              Export CSV
            </Button>
          </div>
        </div>
      </div>
    </Wrapper>
  );
};
export const HomePage = memo(HomePageComponent);
