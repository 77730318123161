import { useCallback, useState } from 'react';
import { toast } from 'react-toastify';
import { client } from '@/app/client';
import { getContract, prepareContractCall, sendTransaction } from 'thirdweb';
import { useActiveAccount } from 'thirdweb/react';
interface IPropsApproveToken {
  chain: any;
  tokenAmount: any;
  spender: string;
  tokenAddress: string;
  onSuccess: () => void;
  onFailed: () => void;
  accountBalance: any;
  accountTokenBalance: any;
}
export const useApproveToken = (props: IPropsApproveToken) => {
  const { tokenAmount, spender, tokenAddress, chain, accountBalance, accountTokenBalance } = props;
  const activeAccount = useActiveAccount();
  const contract = getContract({
    address: tokenAddress,
    chain,
    client
  });
  const [isLoading, setIsLoading] = useState(false);
  const handleApprove = useCallback(async () => {
    if (!activeAccount) return;
    setIsLoading(true);
    if (Number(accountBalance?.value) < 0.01) {
      toast.dismiss();
      setIsLoading(false);
      toast.error('Insufficient native balance for fees!');
      throw new Error('Insufficient native balance for fees!');
    } else {
      console.log('accountTokenBalance', accountTokenBalance, tokenAmount);
      if (tokenAmount?.gt(accountTokenBalance?.value)) {
        toast.dismiss();
        setIsLoading(false);
        toast.error('Insufficient token amount!');
        throw new Error('Insufficient token amount!');
      } else {
        try {
          console.log('spender', spender, tokenAmount, Number(tokenAmount));
          const transaction = prepareContractCall({
            contract,
            method: 'function approve(address spender,uint256 amount)',
            params: [spender, tokenAmount?.toNumber()]
          });
          console.log('giang', transaction);
          const s = await sendTransaction({
            account: activeAccount,
            transaction
          });
          console.log('nhan', s);
          toast.success('Approve token successfully!');
          setIsLoading(false);
        } catch (error: any) {
          toast.error(error?.message || error || 'Something went wrong!');
          console.log('err', error);
          setIsLoading(false);
          toast.dismiss();
          toast.error(error as string);
        }
      }
    }
  }, [accountTokenBalance, accountBalance, tokenAmount, spender, contract, activeAccount]);
  return {
    handleApprove,
    isLoading
  };
};
