import { client } from '@/app/client';
import { useCallback, useMemo, useState } from 'react';
import { useActiveAccount, useWalletBalance } from 'thirdweb/react';
import { useCheckApprove } from './useCheckApprove';
import { toast } from 'react-toastify';
import { useApproveToken } from './useApproveToken';
import { getContract, prepareContractCall, sendTransaction } from 'thirdweb';
import BigNumber from 'bignumber.js';
interface IPropsUseTransfer {
  tokenAddress: string;
  receiverAddress: string[];
  receiverAmount: string[];
  totalAmount: string;
  chain: any;
  onSuccess: () => void;
  onFailed: () => void;
  accountTokenBalance: any;
}

const contractAddress = '0xBC3fD9af88B8D45ba26aDdB0c314dbC08FDCA770';
export const useTransfer = (props: IPropsUseTransfer) => {
  const [isLoading, setIsLoading] = useState(false);
  const [tx, setTx] = useState('');
  const activeAccount = useActiveAccount();
  const { chain, tokenAddress, totalAmount, receiverAddress, receiverAmount, accountTokenBalance } = props;
  const { data: accountBalance } = useWalletBalance(
    {
      chain,
      address: activeAccount?.address,
      client
    },
    {
      refetchInterval: 8000
    }
  );

  const contract = getContract({
    address: contractAddress,
    chain,
    client
  });

  const { data: approval, refetch: refetchApproval } = useCheckApprove(
    activeAccount?.address || '',
    tokenAddress,
    contractAddress,
    chain
  );

  const tokenPow = useMemo(() => {
    const tokenPow = BigNumber(10).pow(accountTokenBalance?.decimals || 0);
    return tokenPow;
  }, [accountTokenBalance]);

  const isApprove = useMemo(() => {
    console.log('totalAmount', totalAmount, approval);
    if (!totalAmount) return true;

    console.log('checkApprove', BigNumber(totalAmount)?.multipliedBy(tokenPow), BigNumber(approval?.toString() || '0'));
    return BigNumber(totalAmount)
      ?.multipliedBy(tokenPow)
      ?.lte(BigNumber(approval?.toString() || '0'));
  }, [totalAmount, approval, tokenPow]);

  console.log('isApprove', isApprove);

  const { handleApprove, isLoading: isLoadingApprove } = useApproveToken({
    chain,
    tokenAmount: BigNumber(totalAmount)?.multipliedBy(tokenPow),
    tokenAddress,
    onSuccess: () => {},
    onFailed: () => {},
    accountBalance,
    accountTokenBalance,
    spender: contractAddress
  });

  console.log('approval', approval, isApprove);
  console.log('accountBalance', accountBalance, accountTokenBalance);
  const handleTransfer = useCallback(async () => {
    setTx('');
    if (!activeAccount) {
      return;
    }
    if (!tokenAddress) {
      toast.dismiss();
      toast.info('Invalid Rewards token address!');
      return;
    }
    console.log('totalAmount', totalAmount);
    if (!totalAmount || Number(totalAmount) === 0) {
      toast.dismiss();
      toast.info('No rewards found, Please check your CSV file!');
      return;
    }

    console.log('isApprove!', !isApprove);
    if (!isApprove) {
      await handleApprove();
      refetchApproval();
      return;
    }
    try {
      setIsLoading(true);
      const receiverAmountParse: any = receiverAmount?.map((item: any) => {
        return BigNumber(item)?.multipliedBy(tokenPow)?.toNumber();
      });
      console.log('receiverAmountParse', receiverAmountParse);
      const transaction = prepareContractCall({
        contract,
        method: 'function bulkTransferERC20Token(address token,address[] recipients,uint256[] amounts)',
        params: [tokenAddress, receiverAddress, receiverAmountParse]
      });
      const { transactionHash } = await sendTransaction({
        account: activeAccount,
        transaction
      });
      setIsLoading(false);
      toast.success('Send tokens successfully!');
      setTx(transactionHash);
      refetchApproval();
    } catch (error: any) {
      toast.error(error?.message || error || 'Something went wrong!');
      setIsLoading(false);
      console.log('err', error);
    }
  }, [
    tokenAddress,
    totalAmount,
    handleApprove,
    isApprove,
    activeAccount,
    receiverAddress,
    receiverAmount,
    contract,
    tokenPow,
    refetchApproval
  ]);
  return {
    handleTransfer,
    isLoading: isLoading || isLoadingApprove,
    isApprove,
    tx
  };
};
