/* eslint-disable react-refresh/only-export-components */
import React, { useMemo } from 'react';
import { ITheme, ThemeProvider as StyledComponentsThemeProvider, createGlobalStyle, css } from 'styled-components';
import { Colors } from './theme.styled';
import { FONT_SIZES } from './theme.fonts';

export const MEDIA_WIDTHS = {
  upToMobileSmall: 410,
  upToExtraSmall: 640,
  upToSmall: 768,
  upToMedium: 1056,
  upToLarge: 1460,
  uptoVeryLarge: 1600
};

export const FONT_WEIGHT = {
  fontRegular: 400,
  fontBold: 700
};
export const FONT_FAMILY = {
  primaryFont: 'Poppins',
  secondaryFont: 'Poppins',
  tertiaryFont: 'Poppins'
};

const mediaWidthTemplates: {
  [width in keyof typeof MEDIA_WIDTHS]: typeof css;
} = Object.keys(MEDIA_WIDTHS).reduce((accumulator, size) => {
  (accumulator as any)[size] = (a: any, b: any, c: any) => css`
    @media (max-width: ${(MEDIA_WIDTHS as any)[size]}px) {
      ${css(a, b, c)}
    }
  `;
  return accumulator;
}, {}) as any;
const white = 'rgba(255, 255, 255, 1)';
const black = '#000000';
export function colors(): Colors {
  return {
    white,
    black
  };
}

export function appTheme(): ITheme {
  return {
    ...colors(),

    mediaWidth: mediaWidthTemplates,
    // font_weight
    fontWeight: FONT_WEIGHT,
    // font_family
    fontFamily: FONT_FAMILY,
    fontSize: FONT_SIZES,
    // css snippets
    flexColumnNoWrap: css`
      display: flex;
      flex-flow: column nowrap;
    `,
    flexRowNoWrap: css`
      display: flex;
      flex-flow: row nowrap;
    `
  };
}

export const ThemeProvider = ({ children }: { children: React.ReactNode }) => {
  const themeObject = useMemo(() => appTheme(), []);

  return <StyledComponentsThemeProvider theme={themeObject}>{children}</StyledComponentsThemeProvider>;
};

export const ThemedGlobalStyle = createGlobalStyle`
  * {
        box-sizing: border-box !important;
        font-family: ${FONT_FAMILY.primaryFont};
        font-size: ${FONT_SIZES.regular};
        font-weight: ${FONT_WEIGHT.fontRegular};
        color: '#09090B';
  }
   ::-webkit-scrollbar {
      width: 4px;
      background: #000;
  }

    ::-webkit-scrollbar-thumb {
        // background: #393812;
        background: linear-gradient(238.93deg, #90caffd6 18.8%, #07ff889e 81.2%);
        -webkit-border-radius: 1ex;
        -webkit-box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.75);
    }
  :root{
    --toastify-color-light: #121212;
    --toastify-color-dark: #121212;
    --toastify-color-info: #3498db;
    --toastify-color-success: #07bc0c;
    --toastify-color-warning: #f1c40f;
    --toastify-color-error: #e74c3c;
    --toastify-color-transparent: rgba(255, 255, 255, 0.7);

    --toastify-icon-color-info: var(--toastify-color-info);
    --toastify-icon-color-success: var(--toastify-color-success);
    --toastify-icon-color-warning: var(--toastify-color-warning);
    --toastify-icon-color-error: var(--toastify-color-error);

    --toastify-toast-width: 320px;
    --toastify-toast-background: #121212;
    --toastify-toast-min-height: 64px;
    --toastify-toast-max-height: 800px;
    --toastify-font-family: "Ubuntu Mono";
    --toastify-z-index: 9999;

    --toastify-text-color-light: #fff;
    --toastify-text-color-dark: #fff;

    --toastify-text-color-info: #fff;
    --toastify-text-color-success: #fff;
    --toastify-text-color-warning: #fff;
    --toastify-text-color-error: #fff;

    --toastify-spinner-color: #616161;
    --toastify-spinner-color-empty-area: #e0e0e0;

    --toastify-color-progress-light: linear-gradient(
      to right,
      #4cd964,
      #5ac8fa,
      #007aff,
      #34aadc,
      #5856d6,
      #ff2d55
    );
    --toastify-color-progress-dark: #bb86fc;
    --toastify-color-progress-info: var(--toastify-color-info);
    --toastify-color-progress-success: var(--toastify-color-success);
    --toastify-color-progress-warning: var(--toastify-color-warning);
    --toastify-color-progress-error: var(--toastify-color-error);
  }
  :root:root {
    --adm-color-success: #00b578;
    --adm-color-warning: #ff8f1f;
    --adm-color-danger: #ff3141;

    --adm-color-white: #ffffff;
    --adm-color-weak: none;
    --adm-color-light: none;
    --adm-color-border: none;
    --adm-color-box: none;
    --adm-button-background-color: transparent;
    --adm-font-size-main: var(--adm-font-size-5);
    --adm-button-border-radius: 52px;
    --adm-center-popup-max-width: 100vw;
    --adm-color-background: transparent;
  }   
  html,body {
        box-sizing: border-box !important;
        margin: 0;
     
  }
  body{
    width:100%;
    
  }
  .app-container{
    width: 100%;
    max-width: 530px;
  }
  .app-input{
    input {
          width: 100%;
          font-size: 20px;
          font-weight: 700;
          letter-spacing: 0em;
          text-align: left;
          color: '#003C55';
        }
    
        input::-webkit-outer-spin-button,
        input::-webkit-inner-spin-button {
          -webkit-appearance: none;
          margin: 0;
        }
        input[type='number'] {
          -moz-appearance: textfield;
          text-align: start;
        }
        input {
          text-align: start;
          background-color: transparent;
          width: 10px;
          font-size: var(--font-lg);
          border: none;
          outline: none;
          padding: 10px;
          width: 100%;
          color: ${(props) => props.theme.color2};
          font-size: 14px;
          padding: 2px;
          &:-webkit-autofill,
          &:-webkit-autofill:hover,
          &:-webkit-autofill:focus,
          &:-webkit-autofill:active {
            transition: background-color 5000s ease-in-out 0s;
            -webkit-text-fill-color: #FFF;
          }
        
        }
  }
  .primary-button{
  background: transparent;
    height: 45px;
    border-radius: 40px;
    padding-left: 24px;
    padding-right: 24px;
    border: 1px solid #FFFFFF;
    width: 100% ;
    .anticon svg{
 
    fill: #181E79;
    @media screen and (max-width: 768px) {
      height: 48px;
}
}
    span {
      font-weight: 500;
      color: #FFFFFF !important;
      font-size: 18px;
    }
    &:hover {
      background: transparent !important;
      border: 1px solid #FFFFFF !important;
      opacity: 0.85;

      text-align: center;
      span {
        color: #FFFFFF !important;
        font-weight: 500;
        font-size: 18px;
      }
    }
}
`;
