import React from 'react';
import { Provider as ProviderRedux } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { persistStore } from 'redux-persist';
import { store } from './store';
import { ThemeProvider, ThemedGlobalStyle } from '@/theme';
import { RouterProvider } from '@/providers/router.provider';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { CredentialProvider } from '@/providers/credential.provider';
import { ThirdwebProvider } from 'thirdweb/react';

let persistor = persistStore(store);
export const App = () => {
  return (
    <ProviderRedux store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <ThemeProvider>
          <ThemedGlobalStyle />
          <ThirdwebProvider>
            <CredentialProvider>
              <RouterProvider />

              <ToastContainer
                position='top-center'
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                pauseOnFocusLoss
                pauseOnHover
              />
            </CredentialProvider>
          </ThirdwebProvider>
        </ThemeProvider>
      </PersistGate>
    </ProviderRedux>
  );
};
