import { Layout } from 'antd';
import styled from 'styled-components';

export const Wrapper = styled(Layout)`
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  overflow: hidden;
  background: #ffffff;
  padding-bottom: 213px;
  min-height: 100vh;
  .ant-table-cell {
    color: black;
  }
  .ant-table-tbody {
    background: #f4f4f5;
    border-radius: 8px;
  }
  .export-button {
    background: #ffffff;
    height: 43px;
    border-radius: 6px;
    padding-left: 24px;
    padding-right: 24px;
    border: 1px solid #e4e4e7;
    .anticon svg {
      fill: #181e79;
      @media screen and (max-width: 768px) {
        height: 48px;
      }
    }
    span {
      font-weight: 500;
      color: #18181b !important;
      font-size: 18px;
    }
    &:hover {
      background: #e4e4e7 !important;
      border: 1px solid #e4e4e7 !important;
      opacity: 0.85;

      text-align: center;
      span {
        color: #18181b !important;
        font-weight: 500;
        font-size: 18px;
      }
    }
  }
  .import-button {
    background: #18181b;
    width: fit-content;
    height: 43px;
    border-radius: 6px;
    padding-left: 24px;
    padding-right: 24px;
    border: 1px solid #ffffff;
    .csv-reader-input {
      width: 100%;
      position: absolute;
      opacity: 0;
    }
    .anticon svg {
      fill: #181e79;
      @media screen and (max-width: 768px) {
        height: 48px;
      }
    }
    span {
      font-weight: 500;
      color: #ffffff !important;
      font-size: 18px;
    }
    &:hover {
      background: #18181b !important;
      border: 1px solid #ffffff !important;
      opacity: 0.85;

      text-align: center;
      span {
        color: #ffffff !important;
        font-weight: 500;
        font-size: 18px;
      }
    }
  }
  .connect-button {
    background: #ef4444;
    height: 43px;
    border-radius: 6px;
    padding-left: 24px;
    padding-right: 24px;
    border: 1px solid #ffffff;
    width: 100%;
    .anticon svg {
      fill: #181e79;
      @media screen and (max-width: 768px) {
        height: 48px;
      }
    }
    span {
      font-weight: 500;
      color: #ffffff !important;
      font-size: 18px;
    }
    &:hover {
      background: #ef4444 !important;
      border: 1px solid #ffffff !important;
      opacity: 0.85;

      text-align: center;
      span {
        color: #ffffff !important;
        font-weight: 500;
        font-size: 18px;
      }
    }
  }
`;
