import { getContract } from 'thirdweb';
import { client } from '@/app/client';
import { useReadContract } from 'thirdweb/react';

export const useCheckApprove = (userWallet: string, tokenAddress: string, spender: string, chain: any) => {
  const contract = getContract({
    client,
    address: tokenAddress,
    chain
  });

  const { data, refetch } = useReadContract({
    contract,
    method: 'function allowance(address owner, address spender) returns (uint256)',
    params: [userWallet, spender],
    queryOptions: {
      refetchInterval: 8000
    }
  });

  console.log('contract', contract, chain, data);
  return { data, refetch };
};
